/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap-frontend');

// Javascript Dependencies
//window.Vue = require('../../node_modules/vue/dist/vue.min');
//Vue.config.productionTip = true;

require('../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js');
require('../../node_modules/jquery-validation/dist/jquery.validate.min.js');
require('../../node_modules/owl.carousel/dist/owl.carousel.min.js');
require('../../node_modules/jquery.easing/jquery.easing.min.js');
window.isotope = require('../../node_modules/isotope-layout/dist/isotope.pkgd.min.js');
window.waypoint = require('../../node_modules/waypoints/lib/jquery.waypoints.min.js');
window.counterUp = require('../../node_modules/jquery.counterup/jquery.counterup.min.js');
window.magnificPopup = require('../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js');
window.wow = require('../../node_modules/wow.js/dist/wow.min.js');
window.AOS = require('../../node_modules/aos/dist/aos.js');
require('./frontend/swiper.min.js');
window.bxSlider = require('../../node_modules/bxslider/dist/jquery.bxslider.min.js');
require('./frontend/custom.js');
require('./scripts/pages/faq.js');

/**
 *
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// Instantsearch
//window.InstantSearch = require('../../node_modules/vue-instantsearch/dist/vue-instantsearch');
//Vue.use(InstantSearch);

// Bootstrap Vue
//import BootstrapVue from '../../node_modules/bootstrap-vue/dist/bootstrap-vue';
//Vue.use(BootstrapVue);

// Vue Pluralize
//import VuePluralize from '../../node_modules/vue-pluralize/dist/vue-pluralize';
//Vue.use(VuePluralize);

// Vue Components
//Vue.component('search', require('./frontend/Search.vue').default);
//Vue.component('test-drive-search', require('./frontend/TestDriveSearch.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//   el: '#app',
// });
